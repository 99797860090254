import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { _objectKeys } from '@naturalcycles/js-lib'
import { hwIdByProductKey, ProductKey, ProductType } from '@naturalcycles/shared'
import { NavService } from '@src/app/core/services/nav.service'
import { getState, SignalStore } from '@src/app/core/store/signalStore'
import { ManageAccountPage, Page } from '@src/app/shared/typings/enum/pages'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class RouteGuardService {
  protected store = inject(SignalStore)

  hasSession(): boolean {
    return !!this.store.$sessionId()
  }

  hasPaymentDue(): boolean {
    const { nextPaymentDate } = this.store.$account()

    if (!nextPaymentDate) return false

    const now = new Date()
    const today = [
      now.getUTCFullYear(),
      (now.getUTCMonth() + 1).toString().padStart(2, '0'),
      now.getUTCDate().toString().padStart(2, '0'),
    ].join('-')

    return nextPaymentDate < today
  }

  hasAccountId(): boolean {
    return !!getState().account.id
  }

  hasGoal(): boolean {
    return !!getState().account.goal
  }

  requiresAppConsent(): boolean {
    return this.hasAccountId() && !getState().account.appConsent
  }

  hasCartItems(): boolean {
    return !!this.store.$cart().items.length
  }

  hasMeasuringDevice(): boolean {
    const { cart } = this.store.getState()
    // This contains all product keys that map to a measuring device
    const measuringDevices = _objectKeys(hwIdByProductKey)
    return cart.items.some(item => measuringDevices.includes(item.key))
  }

  hasValidThermometerSelection(): boolean {
    const therm = this.store.$cart().items.find(item => item.key === ProductKey.THERMOMETER)
    return !therm || !!therm.variant
  }

  hasAddress(): boolean {
    return !this.store.$cart().needsBillingAddress && !this.store.$cart().needsShippingAddress
  }

  cartContainsType(productType: ProductType): boolean {
    return this.store.$cart().items.some(item => item.type === productType)
  }

  cartNotContainsType(productType: ProductType): boolean {
    return this.store.$cart().items.every(item => item.type !== productType)
  }

  accountIsComplete(): boolean {
    return !!getState().account.completeDate
  }

  purchasedSubscription(): boolean {
    return !!getState().account.lastPaymentGate
  }

  /**
   * Does the user have a plan in their cart that cannot be selected on the plans page?
   * @example Prepaid plans (6 Month plans) or trials
   */
  hasNonSelectablePlan(): boolean {
    const { cart } = this.store.getState()
    const product = this.store.$product()
    const selectedPlan = cart.items.find(i => i.type === ProductType.SUBSCRIPTION)
    return !!selectedPlan && !product.items.some(i => i.key === selectedPlan.key)
  }
}

export abstract class RouteGuard extends RouteGuardService {
  page!: Page | ManageAccountPage
  // Use below if we need access to built in Angular parameters
  // canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean
  abstract canActivate(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean>

  protected navService = inject(NavService)
}
